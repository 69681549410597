import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import Layout from "../../layouts";

const connectedenvironment = () => {
  return (
    <Layout title="Connected Environment">
      <Helmet>
        <body className="is-index-page" />
      </Helmet>
      <section className="hero w-screen relative h-screen overflow-y-hidden">
        <div className="z-20 text-4xl text-text-primary dark:text-text-primary-dark font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-light dark:bg-dark-accent p-8 rounded-lg">
          Coming soon
        </div>
        <div className="bg-black/80 absolute inset-0 z-10"></div>
        <StaticImage
          objectFit="cover"
          src="../../static/images/03.jpg"
          className="product-tile w-full h-full object-cover object-center absolute inset-0 transform group-hover:scale-105 transition duration-200 blur-lg"
        />
      </section>
    </Layout>
  );
};

export default connectedenvironment;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
